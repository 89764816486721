export default class Health {
  mediaconverter: boolean
  nodeserver: boolean
  syncserver: boolean
  vsolcloudscp: boolean
  walterpbridge: boolean

  static readonly MEDIACONVERTER_LABEL: string = "Media Converter"
  static readonly NODESERVER_LABEL: string = "Node Server"
  static readonly SYNCSERVER_LABEL: string = "Sync Server"
  static readonly VSOLCLOUDSCP_LABEL: string = "Dicom Server"
  static readonly WALTERPBRIDGE_LABEL: string = "Walter ERP Bridge"

  constructor(
    mediaconverter: boolean = false,
    nodeserver: boolean = false,
    syncserver: boolean = false,
    vsolcloudscp: boolean = false,
    walterpbridge: boolean = false
  ) {
    this.mediaconverter = mediaconverter
    this.nodeserver = nodeserver
    this.syncserver = syncserver
    this.vsolcloudscp = vsolcloudscp
    this.walterpbridge = walterpbridge
  }
}
