
import HealthService from "@/services/HealthService"
import { reactive, ref } from "vue"
import Health from "@/models/Health"

export default {
  name: "Health",
  setup() {
    const healthService = new HealthService()
    const healthObj: Health = reactive(new Health())
    const healthLoading = ref(true)

    getHealth()

    function getHealth() {
      healthService.getHealth().then((response: Health) => {
        Object.assign(healthObj, response)
        healthLoading.value = false
      })
    }

    return {
      healthLoading,
      healthObj,
      Health,
    }
  },
}
